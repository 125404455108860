import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Section1 from './components/Section1';
import Section2 from './components/Section2';
import Section3 from './components/Section3';
import Section4 from './components/Section4';
import Section5 from './components/Section5';


function App() {
  // const trackingId = "G-43BYM66M0Q";  
  // ReactGA.initialize(trackingId);
  // ReactGA.pageview(window.location.pathname);

  useEffect(() => {
    // Initialize Google Analytics
    ReactGA.initialize('G-43BYM66M0Q');
    // To Report Page View 
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);



  return (
    <>
      {/* <Router> */}
        <Navbar />
          {/* <Routes>
            <Route path='/' exact />
          </Routes> */}
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Footer />
      {/* </Router> */}
    </>
  );
}

export default App;
